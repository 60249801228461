<template>
    <v-textarea v-on="$listeners"
                :outlined="outlined"
                v-model="v"
                :type="type"
                :label="label"
                :placeholder="placeholder"
                :persistent-placeholder="persistentPlaceholder"
                :hint="hint"
                :hide-details="hideDetails === undefined ? !hint : hideDetails"
                :persistent-hint="persistentHint"
                :clearable="clearable"
                :counter="counter"
                :maxlength="maxlength"
                :readonly="readonly"
                :disabled="disabled"
                :loading="loading"
                :full-width="fullWidth"
                :rules="rules"
                :prepend-icon="prependIcon"
                :prepend-inner-icon="prependInnerIcon"
                :append-icon="appendIcon"
                :rows="rows"
                :auto-grow="autoGrow"
                :no-resize="noResize"
                @input="$emit('input', $event)"
                @click:append="$emit('appendClick', $event)"
    ></v-textarea>
</template>

<script>
    export default {
        name: 'BaseTextAreaField',
        props: {
            outlined: {type: Boolean, default: true},
            value: [String, Number],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: Boolean,
            counter: {type: Boolean, default: true},
            maxlength: [Number, String],
            readonly: Boolean,
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String,
            rows: {type: Number, default: 5},
            autoGrow: {type: Boolean, default: true},
            noResize: Boolean
        },
        watch: {
            value: function () {
                this.v = this.value;
            }
        },
        data: () => ({
            v: undefined
        }),
        created() {
            this.v = this.value;
        }
    }
</script>

<style scoped>

</style>
